import Cookies from 'js-cookie';

export class CookieManagerService {
  EXPIRY_DAYS = 30;

  ENABLE_ANALYTICS_ID = 'enable_analytics_cookies';

  setEnableAnalyticsCookies = value => {
    this.setCookie(this.ENABLE_ANALYTICS_ID, value);
  };

  analyticsCookiesEnabled = () => {
    // If the user hasn't set this cookie we assume analytical cookies are ok.
    return this.getCookie(this.ENABLE_ANALYTICS_ID) !== 'false';
  };

  setCookie = (name, value, expires = this.EXPIRY_DAYS) => {
    Cookies.set(name, value, { expires });
  };

  getCookie = name => {
    return Cookies.get(name);
  };
}

export default CookieManagerService;
