import React from 'react';
import PropTypes from 'prop-types';
import { CookieManagerService } from './service';
import CheckBox from './check-box';

class CookieManager extends React.Component {
  constructor(props) {
    super(props);

    this.service = new CookieManagerService();

    // Analytics are enabled if the cookie doesn't exist until they open the modal
    // after which it sets to false by default.
    const analytics =
      this.service.getCookie(this.service.ENABLE_ANALYTICS_ID) !== undefined
        ? this.service.analyticsCookiesEnabled()
        : false;

    this.state = {
      essential: true,
      analytics,
    };
  }

  componentDidMount() {
    window.showCookieModal = this.showModal;
    window.acceptAllCookieTypes = this.acceptAllCookieTypes;
  }

  onUserChoiceChange = cookieType => {
    this.setState(prevState => {
      const prevChoice = prevState[cookieType];
      return {
        [cookieType]: !prevChoice,
      };
    });
  };

  showModal = () => {
    $('#cookie-modal').modal('show');
  };

  acceptAllCookieTypes = () => {
    this.service.setEnableAnalyticsCookies(true);
  };

  showCookiePolicy = () => {
    const { cookiePolicyUrl } = this.props;
    window.open(cookiePolicyUrl, '_blank');
  };

  save = () => {
    const { analytics } = this.state;
    this.service.setEnableAnalyticsCookies(analytics);
    $('#cookie-modal').modal('hide');
  };

  render() {
    const { cookiePolicyUrl, essentialText, analyticalText } = this.props;
    const { analytics } = this.state;

    return (
      <div
        aria-label="cookie modal"
        id="cookie-modal"
        className="modal p-4"
        tabIndex="-1"
        role="dialog"
        data-keyboard="false"
        data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content border-0 rounded-0">
            <div className="modal-header">
              <h5 className="modal-title">Consent Preferences</h5>
            </div>
            <div className="modal-body d-flex flex-column">
              <div>
                You can change your options at any time by clicking on Cookie
                Preferences at the bottom of our homepage. For more detailed
                information about the cookies we use, see our{' '}
                <a
                  href={cookiePolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  Cookie Policy
                </a>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <CheckBox
                    title="Essential cookies"
                    defaultChecked
                    disabled
                    cookieType="essential"
                    onUserChoiceChange={this.onUserChoiceChange}
                  />
                </div>
                <div className="col-8">{essentialText}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <CheckBox
                    title="Analytics cookies"
                    defaultChecked={analytics}
                    cookieType="analytics"
                    onUserChoiceChange={this.onUserChoiceChange}
                  />
                </div>
                <div className="col-8">{analyticalText}</div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.showCookiePolicy}>
                Review Cookie Policy
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.save}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CookieManager.propTypes = {
  cookiePolicyUrl: PropTypes.string.isRequired,
  essentialText: PropTypes.string.isRequired,
  analyticalText: PropTypes.string.isRequired,
};

export default CookieManager;
