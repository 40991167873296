import React from 'react';
import PropTypes from 'prop-types';

class CheckBox extends React.Component {
  constructor(props) {
    super(props);

    const { disabled, defaultChecked } = props;

    this.state = {
      disabled: !!disabled,
      defaultChecked: !!defaultChecked,
    };
  }

  onCheckBoxChange = () => {
    const { onUserChoiceChange, cookieType } = this.props;

    onUserChoiceChange(cookieType);
  };

  render() {
    const { defaultChecked, disabled } = this.state;
    const { title } = this.props;
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          aria-label={title}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={this.onCheckBoxChange}
        />
        <span className="form-check-label">{title}</span>
      </div>
    );
  }
}

CheckBox.propTypes = {
  cookieType: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onUserChoiceChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

CheckBox.defaultProps = {
  disabled: false,
};

export default CheckBox;
